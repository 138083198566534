import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EventContainer = styled.div`
  width: 100%;

  ${mediaLargerThan.tablet} {
    max-width: 825px;
    padding: 1.5rem 0;
  }
`;

const EventHeader = styled.div`
  padding: 0 1.5rem 1rem 1.5rem;
  background-color: var(--dark-color);

  ${mediaLargerThan.tablet} {
    background: none;
    padding: 0 0 1rem 0;
  }
`;

const EventTitle = styled.h2`
  color: var(--light-color);
  max-width: 342px;
  font-family: Verdana;
  font-size: 1.25rem;

  ${mediaLargerThan.tablet} {
    max-width: 100%;
  }
`;

const CalendarButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-top: 0.6rem;
  cursor: pointer;
  font-family: Verdana;

  svg {
    width: 10px;
    height: 10px;
  }
`;

const EventContent = styled.div`
  padding: 1.5rem;

  ${mediaLargerThan.tablet} {
    padding: 1.5rem 0;
  }
`;

const EventContentHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

const EventLogo = styled.img`
  border-radius: 35px;
  width: 35px;
  height: 35px;
`;

const EventContentHeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const EventContentHeader = styled.p<{ bold: boolean }>`
  font-family: Montserrat;
  color: --var(--white-color);
  font-weight: ${(p) => (p.bold ? '700' : '400')};
`;

const EventContentDate = styled.h2`
  font-family: Verdana;
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 1.25rem;
`;

const EventDescription = styled.p`
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  margin-top: 1rem;
`;

const EventInfo = styled.div`
  display: grid;
  row-gap: 5px;
  margin-top: 1.25rem;
  font-family: Verdana;
`;

const RowWithIcon = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  svg {
    height: 17px;
    width: 17px;
  }
`;

// TODO: Disabled until accounts implemented
// const EventActionableContainer = styled.div`
//   margin-top: 1.25rem;
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   align-items: center;
//   justify-items: center;
// `;

const TicketButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: var(--light-color);
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  margin-top: 1.25rem;
  color: var(--dark-color);
`;

const EventSocialMediaContainer = styled.div`
  padding: 45px 1.5rem;
  background-color: var(--dark-color);

  ${mediaLargerThan.tablet} {
    padding: 0;
    background-color: transparent;
  }
`;

const EventInfoContainer = styled.div`
  width: 100%;

  ${mediaLargerThan.tablet} {
    display: flex;

    > div {
      width: 50%;
    }

    > :nth-child(1) {
      border-right: 1px solid white;
      padding-right: 1.5rem;
    }

    > :nth-child(2) {
      padding-left: 1.5rem;
    }
  }
`;

const EventInfoHeader = styled.div``;

export const StyledEventPage = {
  LoadingContainer,
  EventContainer,
  EventHeader,
  EventTitle,
  CalendarButtonContainer,
  EventContent,
  EventContentHeaderContainer,
  EventLogo,
  EventContentHeader,
  EventContentHeaderGroup,
  EventContentDate,
  EventDescription,
  EventInfo,
  RowWithIcon,
  IconContainer,
  TicketButton,
  EventSocialMediaContainer,
  EventInfoContainer,
  EventInfoHeader,
};
