import React from 'react';
import styles from './styles.module.css';
import { IoIosClose } from 'react-icons/io';

import search from '../../../assets/images/search.svg';
import { StyledSearchInput } from './styledComponents';
import { useCalendar } from '../../../contexts/CalendarProvider';

const SearchInput: React.FC = () => {
  const { updateSearch, search: searchTerm } = useCalendar();
  const placeholder: string = 'Search by event, group or keyword';

  return (
    <StyledSearchInput.Container>
      <img src={search} />
      <StyledSearchInput.Input
        placeholder={placeholder}
        className={styles.input}
        value={searchTerm}
        onChange={(e) => {
          updateSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />

      <IoIosClose
        size={'40px'}
        className={styles.clear}
        onClick={() => {
          updateSearch('');
        }}
      />
    </StyledSearchInput.Container>
  );
};

export default SearchInput;
