import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

export const CalendarEventsContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mediaLargerThan.tablet} {
    align-items: center;
    height: 100%;
  }
`;

export const StyledCalendar = {
  CalendarEventsContainer,
};
