import axios from 'axios';
import { EventType } from '../types/EventType';
import { CalendarState } from '../../contexts/CalendarProvider';

export interface Organization {
  name: string;
  logo: string | null;
  suborganizations: Suborganization[];
  value: number;
}

interface Suborganization {
  name: string;
}

interface IWhenzyService {
  getEvent: (eventId: string) => Promise<EventType>;
  getOrganizationsV1: () => Promise<Organization[]>;
}

interface GetOrganizationsV1 {
  organization: string;
  category: string;
  value: number;
}

interface GetOrganizationsV2 {
  name: string;
  categories: string[];
  value: number;
  image_url: string;
}

export interface CalendarEventV2 {
  event_id: string;
  title: string;
  time: string | null;
  organization: string;
  category: string;
}

export interface CalendarDay {
  date: string;
  events: CalendarEventV2[];
}

export class WhenzyService implements IWhenzyService {
  private readonly baseUrl = `${process.env.REACT_APP_DATA_URL}`;

  async getEvent(eventId: string): Promise<EventType> {
    const url = new URL(`/calendar/${eventId}`, this.baseUrl);
    const response = await axios.get<EventType>(url.toString());
    return response.data;
  }

  async getEventsV2(calendarState: CalendarState): Promise<CalendarDay[]> {
    const url = new URL(`/calendar`, this.baseUrl);
    const filterOrgs = calendarState.filters.orgs.map((org) => ({
      organization: org.org,
      categories: org.categories,
    }));
    const response = await axios.post<CalendarDay[]>(url.toString(), filterOrgs);

    return response.data;
  }

  async getOrganizationsV1(): Promise<Organization[]> {
    const url = new URL('v1/organizations', this.baseUrl);
    const response = await axios.get<GetOrganizationsV1[]>(url.toString());
    const organizations = response.data.reduce<Organization[]>((accumulator, currentOrg) => {
      const orgIndex = accumulator.findIndex((org) => currentOrg.organization === org.name);
      if (orgIndex === -1) {
        accumulator.push({
          value: currentOrg.value,
          name: currentOrg.organization,
          logo: null,
          suborganizations: currentOrg.category ? [{ name: currentOrg.category }] : [],
        });
      } else {
        if (currentOrg.category) {
          accumulator[orgIndex].suborganizations.push({
            name: currentOrg.category,
          });
        }
      }

      return accumulator;
    }, []);

    return organizations;
  }

  async getOrganizationsV2(): Promise<Organization[]> {
    const url = new URL('organizations', this.baseUrl);
    url.searchParams.append('has_events', 'true');
    const response = await axios.get<GetOrganizationsV2[]>(url.toString());

    const organizations = response.data.reduce<Organization[]>((acc, currentOrg) => {
      acc.push({
        value: currentOrg.value,
        name: currentOrg.name,
        logo: currentOrg.image_url,
        suborganizations: currentOrg.categories.map((cat) => ({
          name: cat,
        })),
      });
      return acc;
    }, []);

    return organizations;
  }
}
