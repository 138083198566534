import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

const Container = styled.div``;

const OrganizationContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const OrgLogo = styled.img`
  border-radius: 30px;
  width: 33px;
  height: 30px;
  background-color: white;
`;

const OrgName = styled.p`
  font-size: 1rem;
  font-family: 'Montserrat';
  width: 189px;
`;

const SuborganizationContainer = styled.div<{ state: TransitionStatus }>`
  transition: max-height 200ms;
  margin-top: 0.5rem;
  max-height: ${({ state }) => {
    switch (state) {
      case 'exited':
      case 'entering':
        return '0';
      case 'entered':
      case 'exiting':
      default:
        return '100％';
    }
  }};
`;

const SuborganizationContainerItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 51px;
  padding: 0.5rem 0rem;
`;

const SuborganizationActionableContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const ArrowButton = styled.span<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  > div {
    height: 20px;
  }
  ${(props) =>
    props.isActive
      ? `
      transform: rotate(180deg);

    `
      : `transform: rotate(90deg);`}
`;

const StyledSidebarItem = {
  Container,
  OrgLogo,
  OrgName,
  OrganizationContainer,
  SuborganizationContainer,
  SuborganizationContainerItem,
  SuborganizationActionableContainer,
  ArrowButton,
};

export default StyledSidebarItem;
