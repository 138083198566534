import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

const Container = styled.div`
  display: flex;
  overflow-y: scroll;
  position: relative;
  height: 90vh;
  flex-direction: column;
  background-color: var(--gray-color);

  ${mediaLargerThan.tablet} {
    max-width: 825px;
    width: 100%;
    flex-direction: row;
  }
`;

export const StyledCalendarPage = {
  Container,
};
