import React, { useEffect, useRef, useState } from 'react';
import HeaderNavigation from '../navigation/HeaderNavigation';
import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';
import { StyledHeader } from './styledComponents';
import { DateTime } from 'luxon';
import { LocationInfo, WeatherInfo, getWeather } from '../../../utils/api/getData';
import { getWeatherIconUrl } from '../../../utils/funcs/weather';
import { CSSTransition, TransitionStatus } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import Logo from '../../Sidebar/Logo';
import { useModal } from '../../../utils/modal/useModal';
import WeatherDetails from '../../WeatherDetails';

export type HeaderProps = {
  isCloseNav: boolean;
  setIsCloseNav: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSideBarOpen: (isOpen: boolean) => void;
  isSidebarOpen: boolean;
};

const Header: React.FC<HeaderProps> = ({
  isCloseNav,
  setIsCloseNav,
  setIsSideBarOpen,
  isSidebarOpen,
}) => {
  const [weatherData, setWeatherData] = useState<WeatherInfo | null>(null);
  const location = useLocation();
  const modal = useModal();
  const today = DateTime.now();
  const nodeRef = useRef(null);

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const fetchCurrentWeather = async () => {
      const location: LocationInfo = {
        latitude: 39.97,
        longitude: -74.95,
        locationName: 'Moorestown, NJ',
      };

      try {
        const weather = await getWeather(location, 'imperial');
        setWeatherData(weather);
      } catch (e) {
        console.error('Unable to fetch weather');
      }
    };

    if (!weatherData) {
      fetchCurrentWeather();
    }
  }, [weatherData]);

  const openWeatherModal = () => {
    modal.open(<WeatherDetails weather={weatherData} getWeatherIconUrl={getWeatherIconUrl} />);
  };

  return (
    <>
      <MobileHeader>
        <div>
          <StyledHeader.StyledLink
            to="/"
            onClick={() => {
              setIsCloseNav(true);
            }}
          >
            <MobileMainTitle>WHENZY</MobileMainTitle>
          </StyledHeader.StyledLink>
          <HeaderSubtitle>OF MOORESTOWN</HeaderSubtitle>
        </div>
        <HeaderNavigation setIsCloseNav={setIsCloseNav} isCloseNav={isCloseNav} />
        <script src="https://cdn.usefathom.com/script.js" data-site="SWQCCCWT" defer></script>
      </MobileHeader>
      <StyledHeader.Container>
        <TitleContainer>
          <CSSTransition
            nodeRef={nodeRef}
            in={!isSidebarOpen || !isHomePage}
            timeout={100}
            unmountOnExit
          >
            {(state) => (
              <LogoContainer state={state}>
                <Logo />
              </LogoContainer>
            )}
          </CSSTransition>
          <MainTitle onClick={() => setIsSideBarOpen(!isSidebarOpen)}>
            {today.toFormat('cccc, LLLL d')} |{' '}
            {weatherData?.current.temperature && Math.round(weatherData.current.temperature) + '°F'}
          </MainTitle>
          {weatherData?.current.icon_name && (
            <img
              onClick={openWeatherModal}
              src={getWeatherIconUrl(weatherData.current.icon_name)}
            />
          )}
        </TitleContainer>
        <HeaderNavigation setIsCloseNav={setIsCloseNav} isCloseNav={isCloseNav} />
      </StyledHeader.Container>
    </>
  );
};

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 44, 65, 0.4);
  background-color: var(--dark-color);
  border-bottom: none;
  height: 10vh;
  padding: 0 26px;
  position: fixed;
  z-index: 60;
  width: 100%;

  ${mediaLargerThan.tablet} {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }
`;

const MainTitle = styled.h1`
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;

  ${mediaLargerThan.tablet} {
    font-size: 1.25rem;
  }
`;

const MobileMainTitle = styled.h1`
  font-size: 20px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 4px;
  -webkit-text-fill-color: var(--light-color);
  -webkit-text-stroke-color: var(--light-color);
  -webkit-text-stroke-width: 1.2px;

  ${mediaLargerThan.mobile} {
    font-size: 28px;
  }
  ${mediaLargerThan.tablet} {
    font-size: 32px;
  }
`;

const HeaderSubtitle = styled.h2`
  font-size: 9px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 2.5px;
  cursor: default;

  ${mediaLargerThan.mobile} {
    font-size: 14px;
  }

  ${mediaLargerThan.tablet} {
    font-size: 16px;
  }
`;

const LogoContainer = styled.div<{ state: TransitionStatus }>`
  margin-right: 2rem;
  transition: 100ms opacity;
  opacity: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return '0';
      case 'exiting':
      case 'entered':
        return '1';
    }
  }};
`;

export default Header;
