import React, { useState } from 'react';
import './global.css';
import Header from '../layout/Header';
import { StoreProvider } from '../../utils/store/StoreProvider';
import { ModalProvider } from '../../utils/modal/ModalProvider';
import ModalBoundary from '../modal/ModalBoundary';
import { Route, Routes, useLocation } from 'react-router-dom';
import CalendarPage from '../../pages/calendar';
import AboutPage from '../../pages/about';
import ContactPage from '../../pages/contact';
import CoverNavigation from '../layout/navigation/CoverNavigation';
import Event from '../../pages/event';
import Sidebar from '../Sidebar';
import StyledApp from './styledComponents';
import { CalendarProvider } from '../../contexts/CalendarProvider';
import { useWindowSize } from '../../hooks/useWindowSize';

export const fixedHeightPages: string[] = ['/'];

export type AppProps = {};

const App: React.FC<AppProps> = () => {
  const MOBILE_WIDTH = 541;
  const { width } = useWindowSize();
  const [isCloseNav, setIsCloseNav] = useState<boolean>(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(width > MOBILE_WIDTH);
  const location = useLocation();

  const isCalendarPage = location.pathname === '/';

  return (
    <StoreProvider>
      <ModalProvider>
        <CalendarProvider>
          <StyledApp.Container>
            {isCalendarPage && (
              <Sidebar setIsSideBarOpen={setIsSidebarOpen} isOpen={isSidebarOpen} />
            )}
            <StyledApp.Main>
              <Header
                isCloseNav={isCloseNav}
                setIsCloseNav={setIsCloseNav}
                setIsSideBarOpen={setIsSidebarOpen}
                isSidebarOpen={isSidebarOpen}
              />
              <StyledApp.Divider />
              {!isCloseNav && <CoverNavigation close={() => setIsCloseNav(true)} />}
              <StyledApp.PageContainer>
                <Routes>
                  <Route path="/" element={<CalendarPage setIsSidebarOpen={setIsSidebarOpen} />} />
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/event/:eventId" element={<Event />} />
                </Routes>
              </StyledApp.PageContainer>
            </StyledApp.Main>
          </StyledApp.Container>
          <ModalBoundary />
        </CalendarProvider>
      </ModalProvider>
    </StoreProvider>
  );
};

export default App;
