const SidebarClose = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="27" viewBox="0 0 25 27" fill="none">
    <rect
      x="0.75"
      y="1.25"
      width="23.5"
      height="24.5"
      rx="1.25"
      stroke="#EFF7FF"
      strokeWidth="1.5"
    />
    <line x1="8.75" y1="0.5" x2="8.75" y2="26.5" stroke="#EFF7FF" strokeWidth="1.5" />
  </svg>
);

export default SidebarClose;
