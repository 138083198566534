import { useRef } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Transition } from 'react-transition-group';

import SidebarItem from './Item';
import StyledSidebar from './styledComponents';
import Logo from './Logo';
import SidebarClose from '../../assets/images/Icons/SidebarClose';
import { useSidebar } from './useSidebar';
import { useCalendar } from '../../contexts/CalendarProvider';

interface SidebarProps {
  isOpen: boolean;
  setIsSideBarOpen: (isOpen: boolean) => void;
}

const Sidebar = ({ isOpen, setIsSideBarOpen }: SidebarProps) => {
  const sidebarData = useSidebar();
  const ref = useRef(null);
  const closeRef = useRef(null);
  const { calendar, addCategoryFilter, removeCategoryFilter, addOrg, removeOrg } = useCalendar();
  const selectedCalendars = calendar.filters.orgs.length;

  const sortedOrganizations = sidebarData.organizations.sort((a, b) => {
    const isAToggledOn = !!calendar.filters.orgs.find((orgFilter) => orgFilter.org === a.name);
    const isBToggledOn = !!calendar.filters.orgs.find((orgFilter) => orgFilter.org === b.name);

    if (a.name === 'Featured') {
      return -1;
    } else if (b.name === 'Featured') {
      return 1;
    }

    if (isAToggledOn) {
      return -1;
    } else if (isBToggledOn) {
      return 1;
    }

    return a.value > b.value ? -1 : 1;
  });

  return (
    <Transition nodeRef={closeRef} in={!isOpen} timeout={200}>
      {(closeState) => (
        <StyledSidebar.Container state={closeState}>
          <StyledSidebar.ContainerWrapper>
            <Transition nodeRef={ref} in={isOpen} timeout={200}>
              {(openState) => (
                <StyledSidebar.ContentWrapper state={openState}>
                  <StyledSidebar.ContentContainer>
                    <StyledSidebar.LogoContainer state={openState}>
                      <Logo />
                    </StyledSidebar.LogoContainer>
                    <StyledSidebar.FunctionContainer state={openState}>
                      {/* <StyledSidebar.SearchContainer state={openState}>
                        <Search />
                        <StyledSidebar.Input
                          onChange={(event) => updateSearch(event.target.value)}
                          placeholder="Search calendar"
                        />
                      </StyledSidebar.SearchContainer> */}
                      <StyledSidebar.SidebarButton
                        state={closeState}
                        onClick={() => setIsSideBarOpen(!isOpen)}
                      >
                        <SidebarClose />
                      </StyledSidebar.SidebarButton>
                    </StyledSidebar.FunctionContainer>
                    <StyledSidebar.ItemContainerHeader state={openState}>
                      <h2>Calendars</h2>
                      <StyledSidebar.CalendarNumber>
                        {selectedCalendars}/{sidebarData.organizations.length}
                      </StyledSidebar.CalendarNumber>
                    </StyledSidebar.ItemContainerHeader>
                    <StyledSidebar.ItemContainer state={openState}>
                      {sortedOrganizations.map((o) => {
                        return (
                          <SidebarItem
                            key={o.name}
                            orgLogo={o.logo}
                            calendarFilter={calendar}
                            organizationName={o.name}
                            suborganizations={o.suborganizations}
                            addCategory={addCategoryFilter}
                            removeCategory={removeCategoryFilter}
                            addOrg={addOrg}
                            removeOrg={removeOrg}
                          />
                        );
                      })}
                    </StyledSidebar.ItemContainer>
                  </StyledSidebar.ContentContainer>
                  {isOpen && (
                    <StyledSidebar.CloseContainer onClick={() => setIsSideBarOpen(false)}>
                      <IoMdClose size={48} />
                    </StyledSidebar.CloseContainer>
                  )}
                </StyledSidebar.ContentWrapper>
              )}
            </Transition>
          </StyledSidebar.ContainerWrapper>
        </StyledSidebar.Container>
      )}
    </Transition>
  );
};

export default Sidebar;
