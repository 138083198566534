import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: none;
  width: 100%;

  ${mediaLargerThan.tablet} {
    display: flex;
    max-width: 825px;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0 1rem;
  }
`;

const StyledLink = styled(Link)`
  color: var(--light-color);
`;

export const StyledHeader = {
  Container,
  StyledLink,
};
