import React, { useRef } from 'react';
import styles from './styles.module.css';
import mainStyles from '../../pages/calendar/styles.module.css';
import CalendarBody from './CalendarBody';

import CalendarHeader from './CalendarHeader';
import { useCalendarEvents } from './useCalendarEvents';
import { CalendarEventsContainer } from './styledComponents';
import { ClipLoader } from 'react-spinners';

export type CalendarProps = {
  onScroll: (scrollTop: number) => void;
  setIsSidebarOpen: (isOpen: boolean) => void;
};

const Calendar: React.FC<CalendarProps> = ({ onScroll, setIsSidebarOpen }) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const { events: calendarEvents, isLoading } = useCalendarEvents();

  return (
    <div className={`${mainStyles.block} ${styles.calendar}`} ref={calendarContainerRef}>
      <CalendarHeader setIsSidebarOpen={setIsSidebarOpen} />

      {isLoading ? (
        <CalendarEventsContainer>
          <ClipLoader color="#F4D35E" size={50} />
        </CalendarEventsContainer>
      ) : calendarEvents.length > 0 ? (
        <CalendarBody events={calendarEvents} onScrollChange={onScroll} />
      ) : (
        <CalendarEventsContainer>
          <h2>No events to display. Try adding calendars from the sidebar menu.</h2>
        </CalendarEventsContainer>
      )}
    </div>
  );
};

export default Calendar;
