import { Switch } from '@mui/material';
import styled from 'styled-components';

const Toggle = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: var(--light-color);

    &:hover {
      background-color: alpha(var(--light-color), 0.5);
    }
  }

  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--light-color);
  }
`;

export default Toggle;
