import { createContext, useContext, useState } from 'react';

type CalendarProviderProps = {
  children: React.ReactNode;
};

type OrgFilter = {
  org: string;
  categories: string[];
};

type CalendarFilter = {
  orgs: OrgFilter[];
};

export type CalendarState = {
  filters: CalendarFilter;
};

export interface Suborganization {
  name: string;
}

type CalendarStateContext = {
  calendar: CalendarState;
  addCategoryFilter: (mainOrg: string, category: string) => void;
  removeCategoryFilter: (mainOrg: string, category: string) => void;
  addOrg: (mainOrg: string, subcategories: Suborganization[]) => void;
  removeOrg: (removedOrg: string) => void;
  updateSearch: (search: string) => void;
  search: string;
};

const CalendarContext = createContext<CalendarStateContext | undefined>(undefined);

const CalendarProvider = ({ children }: CalendarProviderProps): React.ReactElement => {
  const [calendar, setCalendar] = useState<CalendarState>({
    filters: {
      orgs: [{ org: 'Featured', categories: [] }],
    },
  });
  const [search, setSearch] = useState('');

  const addCategoryFilter = (mainOrg: string, category: string): void => {
    setCalendar((prevState) => {
      const orgs = prevState.filters.orgs;
      const targetOrgFilter = orgs.findIndex((target) => target.org === mainOrg);

      if (targetOrgFilter === -1) {
        orgs.push({
          org: mainOrg,
          categories: [category],
        });
      } else {
        const orgCategories = orgs[targetOrgFilter].categories;
        orgs[targetOrgFilter].categories = [...orgCategories, category];
      }

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          orgs,
        },
      };
    });
  };

  const removeCategoryFilter = (mainOrg: string, removedCategory: string): void => {
    setCalendar((prevState) => {
      const orgs = prevState.filters.orgs;
      const targetOrderFilter = orgs.findIndex((target) => target.org === mainOrg);

      if (targetOrderFilter === -1) {
        return prevState;
      } else {
        orgs[targetOrderFilter].categories = orgs[targetOrderFilter].categories.filter(
          (category) => category !== removedCategory,
        );
      }

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          orgs,
        },
      };
    });
  };

  const addOrg = (mainOrg: string, categories: Suborganization[]): void => {
    setCalendar((prevState) => {
      const newOrgs = [...prevState.filters.orgs];
      newOrgs.push({
        org: mainOrg,
        categories: categories.map((cat) => cat.name),
      });

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          orgs: newOrgs,
        },
      };
    });
  };

  const removeOrg = (removedOrg: string): void => {
    setCalendar((prevState) => {
      const orgsList = prevState.filters.orgs.filter((orgFilter) => orgFilter.org !== removedOrg);

      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          orgs: orgsList,
        },
      };
    });
  };

  const updateSearch = (search: string): void => {
    setSearch(search);
  };

  const value: CalendarStateContext = {
    calendar,
    addCategoryFilter,
    removeCategoryFilter,
    addOrg,
    removeOrg,
    updateSearch,
    search,
  };
  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};

function useCalendar() {
  const context = useContext(CalendarContext);
  if (context === undefined) {
    throw new Error('useCalendar must be used within a CalendarProvider');
  }

  return context;
}

export { CalendarProvider, useCalendar };
