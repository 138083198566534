import React from 'react';
import styled from 'styled-components';
import SearchInput from './SearchInput';
// import Messages from './Messages'; TODO when realise chat support
import { mediaLargerThan } from '../../common/styles';

export type ChatProps = {};

const Chat: React.FC<ChatProps> = () => {
  return (
    <ChatWrapper>
      <SearchInput />
    </ChatWrapper>
  );
};

const ChatWrapper = styled.div`
  background-color: var(--dark-color);
  border-radius: 10px;
  position: fixed;
  bottom: 0;

  ${mediaLargerThan.tablet} {
    position: absolute;
    bottom: 1rem;
    width: 90%;
    max-width: 825px;
    padding: 1rem;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    box-shadow: 3px -4px 4px rgba(33, 44, 65, 0.6);
  }
`;

export default Chat;
