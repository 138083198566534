import React from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { StyledHeaderNavigation } from './styledComponents';

import burger from '../../../../assets/images/burger.svg';
import close from '../../../../assets/images/x-close.svg';
import styled from 'styled-components';

interface HeaderNavigationProps {
  isCloseNav: boolean;
  setIsCloseNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const HeaderNavigation = ({
  isCloseNav,
  setIsCloseNav,
}: HeaderNavigationProps): React.ReactElement => {
  return (
    <StyledHeaderNavigation.Container>
      <div className={`${styles.navigation} ${styles.headerNavigation}`}>
        <Link className={styles.navLink} to="/about">
          About
        </Link>
        <Link className={styles.navLink} to="/contact">
          Contact
        </Link>
      </div>
      <StyledImage
        className={styles.navMenu}
        src={isCloseNav ? burger : close}
        onClick={() => setIsCloseNav(!isCloseNav)}
      />
    </StyledHeaderNavigation.Container>
  );
};

const StyledImage = styled.img`
  &::hover {
    opacity: 0.75;
  }

  &::active {
    opacity: 0.5;
  }
`;

export default HeaderNavigation;
