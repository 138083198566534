import { useRef, useState } from 'react';

import StyledSidebarItem from './styledComponents';
import Toggle from '../../Toggle';
import Checkbox from '../../Checkbox';
import OrgPlaceholder from '../../../assets/images/org-placeholder.svg';
import { Transition } from 'react-transition-group';
import { CalendarState, Suborganization } from '../../../contexts/CalendarProvider';

interface SidebarItemProps {
  organizationName: string;
  calendarFilter: CalendarState;
  orgLogo: string | null;
  suborganizations: Suborganization[];
  addCategory: (org: string, category: string) => void;
  removeCategory: (org: string, removedCategory: string) => void;
  addOrg: (org: string, subcategories: Suborganization[]) => void;
  removeOrg: (org: string) => void;
}

const SidebarItem = ({
  organizationName,
  suborganizations,
  orgLogo,
  addCategory,
  addOrg,
  removeOrg,
  removeCategory,
  calendarFilter,
}: SidebarItemProps) => {
  const subOrgRef = useRef(null);
  const isSelected = !!calendarFilter.filters.orgs.find(
    (orgFilter) => orgFilter.org === organizationName,
  );
  const [isOpened, setIsOpened] = useState(true);
  return (
    <StyledSidebarItem.Container>
      <StyledSidebarItem.OrganizationContainer>
        <StyledSidebarItem.OrgLogo src={orgLogo || OrgPlaceholder} />
        <StyledSidebarItem.OrgName>{organizationName}</StyledSidebarItem.OrgName>
        <StyledSidebarItem.SuborganizationActionableContainer>
          {isSelected && organizationName !== 'Featured' && (
            <StyledSidebarItem.ArrowButton
              onClick={() => setIsOpened(!isOpened)}
              isActive={isOpened}
            >
              <div>^</div>
            </StyledSidebarItem.ArrowButton>
          )}
          <Toggle
            checked={isSelected}
            onChange={() => {
              if (isSelected) {
                removeOrg(organizationName);
              } else {
                addOrg(organizationName, suborganizations);
              }
            }}
            size="small"
          />
        </StyledSidebarItem.SuborganizationActionableContainer>
      </StyledSidebarItem.OrganizationContainer>
      {organizationName !== 'Featured' && isOpened && (
        <Transition
          unmountOnExit
          nodeRef={subOrgRef}
          in={isSelected && suborganizations.length > 0}
          timeout={200}
        >
          {(state) => (
            <StyledSidebarItem.SuborganizationContainer state={state}>
              {suborganizations.map((suborg) => {
                const targetOrg = calendarFilter.filters.orgs.find(
                  (orgFilter) => orgFilter.org === organizationName,
                );

                const isCategorySelected = targetOrg?.categories.find((cat) => cat === suborg.name);
                return (
                  <StyledSidebarItem.SuborganizationContainerItem key={suborg.name}>
                    <StyledSidebarItem.OrgName>{suborg.name}</StyledSidebarItem.OrgName>
                    <Checkbox
                      sx={{
                        '&.MuiCheckbox-root': {
                          borderRadius: 0,
                          padding: 0,
                        },
                        '&.Mui-checked': {
                          backgroundColor: 'white',
                        },
                        '& svg': {
                          fontSize: 18,
                          scale: '1.4',
                        },
                      }}
                      checked={!!isCategorySelected}
                      onChange={(_event, checked) => {
                        if (checked) {
                          addCategory(organizationName, suborg.name);
                        } else {
                          removeCategory(organizationName, suborg.name);
                        }
                      }}
                    />
                  </StyledSidebarItem.SuborganizationContainerItem>
                );
              })}
            </StyledSidebarItem.SuborganizationContainer>
          )}
        </Transition>
      )}
    </StyledSidebarItem.Container>
  );
};

export default SidebarItem;
