import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

const Container = styled.div<{ state: TransitionStatus }>`
  position: fixed;
  height: 100vh;
  z-index: 70;
  background-color: rgba(51, 62, 83, 0.9);

  width: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return '100%';
      case 'entered':
      case 'exiting':
      default:
        return '0';
    }
  }};

  ${mediaLargerThan.tablet} {
    display: flex;
    position: static;
    width: auto;
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentContainer = styled.div`
  padding: 1rem 2rem;
  transition: opacity 0.2s;
`;

const ContentWrapper = styled.div<{ state: TransitionStatus }>`
  position: relative;
  height: 100%;
  transition: width 0.2s;
  background-color: var(--dark-color);

  width: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return '0';
      case 'entered':
      case 'exiting':
      default:
        return '338px';
    }
  }};
`;

const Item = styled.div``;

const LogoContainer = styled.div<{ state: TransitionStatus }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  visibility: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return 'hidden';
      case 'exiting':
      case 'entered':
        return 'visible';
    }
  }};

  ${mediaLargerThan.tablet} {
    align-items: center;
  }
`;

const ItemContainer = styled.div<{ state: TransitionStatus }>`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  max-height: 65vh;
  padding-right: 0.5rem;
  overflow-y: scroll;

  visibility: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return 'hidden';
      case 'exiting':
      case 'entered':
        return 'visible';
    }
  }};
`;

const Input = styled.input`
  background-color: transparent;
  color: var(--white-color);
  border: none;
  font-family: 'Montserrat';
  outline: 0;
  width: 100%;

  &::placeholder {
    color: var(--white-color);
    font-family: 'Montserrat';
    font-weight: 500;
  }
`;

const SearchContainer = styled.div<{ state: TransitionStatus }>`
  display: flex;
  gap: 0.5rem;
  padding: 6px 9px;
  border-radius: 8px;
  border: 1.5px solid #eff7ff26;
  width: 100%;

  &:focus-within {
    border: 1px solid white;

    svg path {
      fill: var(--light-color);
    }
  }

  visibility: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return 'hidden';
      case 'exiting':
      case 'entered':
        return 'visible';
    }
  }};

  ${mediaLargerThan.tablet} {
    max-width: 242px;
  }
`;

const FunctionContainer = styled.div<{ state: TransitionStatus }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin: 1rem 0;

  ${mediaLargerThan.tablet} {
    border-bottom: 1px solid var(--gray-color);
    width: 100%;
    margin-left: 1rem;
    padding-bottom: 1rem;
  }
`;

const SidebarButton = styled.button<{ state: TransitionStatus }>`
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: #333e53;
  padding: 0.25rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  border-radius: 2px;
  display: none;
  position: relative;

  &:hover {
    rect,
    line {
      stroke: var(--light-color);
    }
  }

  ${mediaLargerThan.tablet} {
    display: block;
  }
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 0;
  right: -3rem;
  cursor: pointer;

  ${mediaLargerThan.tablet} {
    display: none;
  }
`;

const ItemContainerHeader = styled.div<{ state: TransitionStatus }>`
  display: flex;
  justify-content: space-between;

  visibility: ${({ state }) => {
    switch (state) {
      case 'entering':
      case 'exited':
        return 'hidden';
      case 'exiting':
      case 'entered':
        return 'visible';
    }
  }};
  margin: 1rem 0;
  font-weight: bold;

  h2 {
    font-size: 1.25rem;
  }
`;

const CalendarNumber = styled.h2`
  color: var(--light-color);
`;

const StyledSidebar = {
  Container,
  Item,
  ContentContainer,
  ContentWrapper,
  LogoContainer,
  ItemContainer,
  Input,
  SearchContainer,
  FunctionContainer,
  SidebarButton,
  CloseContainer,
  ContainerWrapper,
  ItemContainerHeader,
  CalendarNumber,
};

export default StyledSidebar;
