import { Checkbox as MuiCheckbox } from '@mui/material';
import styled from 'styled-components';

const Checkbox = styled(MuiCheckbox)`
  color: var(--light-color);

  &&.MuiCheckbox-root {
    color: white;
  }

  &&.Mui-checked {
    color: var(--light-color);
  }
`;

export default Checkbox;
