import { Link } from 'react-router-dom';
import { StyledLogo } from './styledComponents';

const Logo = () => (
  <>
    <Link to="/">
      <StyledLogo.MainTitle>WHENZY</StyledLogo.MainTitle>
    </Link>
    <StyledLogo.HeaderSubtitle>OF MOORESTOWN</StyledLogo.HeaderSubtitle>
  </>
);

export default Logo;
