import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;

  ${mediaLargerThan.tablet} {
    display: none;
  }
`;

export const StyledCalendarHeader = { Container };
