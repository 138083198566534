import { useEffect, useState } from 'react';
import { WhenzyService, Organization } from '../../utils/clients/WhenzyService';

interface UseSidebarReturnValues {
  organizations: Organization[];
}

const whenzyService = new WhenzyService();

export const useSidebar = (): UseSidebarReturnValues => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  useEffect(() => {
    async function getOrganizationData() {
      const organizationsData = await whenzyService.getOrganizationsV2();
      setOrganizations(organizationsData);
    }

    getOrganizationData();
  }, []);

  return {
    organizations,
  };
};
