import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  align-items: center;
  border: 1.5px solid #d0d4da;
  height: 42px;
  border-radius: 0.5rem;
  justify-items: center;

  > svg {
    height: 40px;
    width: 40px;
  }
`;

const Input = styled.input``;

export const StyledSearchInput = {
  Container,
  Input,
};
