import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

const Container = styled.div`
  font-size: 14px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 12px;
  cursor: default;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 100%;
  background-color: var(--dark-color);

  ${mediaLargerThan.tablet} {
    display: none;
  }
`;

export const StyledDateWeatherPanel = { Container };
