import styled from 'styled-components';
import { mediaLargerThan } from '../../../common/styles';

const MainTitle = styled.h1`
  font-size: 2rem;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 4px;
  text-wrap: nowrap;
  -webkit-text-fill-color: var(--light-color);
  -webkit-text-stroke-color: var(--light-color);
  -webkit-text-stroke-width: 1.2px;

  ${mediaLargerThan.mobile} {
    font-size: 1.75rem;
  }

  ${mediaLargerThan.tablet} {
    font-size: 2rem;
  }
`;

const HeaderSubtitle = styled.h2`
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  cursor: default;
  font-weight: 400;
  text-wrap: nowrap;

  ${mediaLargerThan.mobile} {
    font-size: 0.875rem;
  }

  ${mediaLargerThan.tablet} {
    font-size: 1rem;
  }
`;

export const StyledLogo = {
  HeaderSubtitle,
  MainTitle,
};
