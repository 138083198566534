import styled from 'styled-components';
import { mediaLargerThan } from '../../common/styles';

const Container = styled.div`
  display: flex;
  padding: 0;
  overflow-x: hidden;

  ${mediaLargerThan.tablet} {
    width: 100%;
    background-color: var(--gray-color);
    min-height: 100%;
  }
`;

const Main = styled.div`
  width: 100%;
  min-width: 338px;
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--gray-color);

  ${mediaLargerThan.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const MainWrapper = styled.div``;

const Divider = styled.div`
  background-color: rgba(33, 44, 65, 0.4);
  width: 100%;
  padding-bottom: 1px;
  display: none;

  ${mediaLargerThan.tablet} {
    display: block;
  }
`;

const PageContainer = styled.div`
  padding-top: 10vh;
  width: 100%;

  ${mediaLargerThan.tablet} {
    max-width: 825px;
    padding: 0 1rem;
  }
`;

const StyledApp = {
  Container,
  Main,
  MainWrapper,
  Divider,
  PageContainer,
};

export default StyledApp;
