import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { CalendarDay, CalendarEventV2, WhenzyService } from '../../utils/clients/WhenzyService';
import { useCalendar } from '../../contexts/CalendarProvider';
import { doesStringMatchQuery } from '../../utils/search/search';

export interface CalendarEvent {
  date: string;
  organization: string;
  category: string;
  event_id: string;
  title: string;
  time: string | null;
  address: string;
}

interface UseCalendarReturnValues {
  events: CalendarEvent[];
  isLoading: boolean;
}

export const useCalendarEvents = (): UseCalendarReturnValues => {
  const { calendar, search } = useCalendar();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getEvents = useCallback(async () => {
    const whenzyService = new WhenzyService();
    setIsLoading(true);
    try {
      const events = await whenzyService.getEventsV2(calendar);
      const calendarEvents: CalendarEvent[] = [];

      events.forEach((e: CalendarDay) => {
        e.events.forEach((event: CalendarEventV2) => {
          calendarEvents.push({
            date: e.date,
            organization: event.organization,
            category: event.category,
            event_id: event.event_id,
            title: event.title,
            time: event.time,
            address: '',
          });
        });
      });
      setEvents(calendarEvents);
    } catch (e) {
      console.error('Something went wrong fetching events', e);
    } finally {
      setIsLoading(false);
    }
  }, [calendar]);

  const delayedSearch = useMemo(
    () =>
      debounce((searchTerm: string) => {
        setEvents((previousEvents) => {
          if (!searchTerm) {
            // TEMPORARY SOLUTION REMOVE ONCE BACKEND SUPPORTS SEARCHING
            getEvents();
            return previousEvents;
          }

          const newEvents = previousEvents.filter((e) => {
            return doesStringMatchQuery(searchTerm, e.title);
          });

          return newEvents;
        });
      }, 500),
    [getEvents],
  );

  useEffect(() => {
    if (isLoading) {
      getEvents();
    }
  }, [getEvents, isLoading]);

  useEffect(() => {
    delayedSearch(search);
  }, [delayedSearch, search]);

  return {
    events,
    isLoading,
  };
};
