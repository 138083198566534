import { ClipLoader } from 'react-spinners';
import { DateTime } from 'luxon';
import LeftArrow from '../../assets/images/Icons/LeftArrow';
import Clock from '../../assets/images/Icons/Clock';
import Location from '../../assets/images/Icons/Location';
import Cost from '../../assets/images/Icons/Cost';
// import Age from '../../assets/images/Icons/Age';
import { StyledEventPage } from './styles';
import SocialMediaEmbed from '../../components/SocialMediaEmbed';
import { useEventPage } from './useEventPage';

interface RowIcon {
  text?: string | null;
  icon: React.ReactElement;
  key: string;
}

const Event = () => {
  const { loading, error, event, handleBackToCalendar } = useEventPage();

  if (loading) {
    // Add a load spinner
    return (
      <StyledEventPage.LoadingContainer>
        <ClipLoader color="#F4D35E" size={50} />
      </StyledEventPage.LoadingContainer>
    );
  }

  if (error || !event) {
    // Show an error message
    return <p>{error}</p>;
  }

  const eventRowIcons: RowIcon[] = [
    {
      key: 'time',
      text: event.time,
      icon: <Clock />,
    },
    {
      key: 'location',
      text: event.address,
      icon: <Location />,
    },
    {
      key: 'cost',
      text: event.cost,
      icon: <Cost />,
    },
    // TODO: Age not implemented in current API
    // {
    //   key: 'age',
    //   text: event.age,
    //   icon: <Age />
    // }
  ];

  const eventDate = DateTime.fromFormat(event.date, 'MM/dd/yyyy');
  return (
    <StyledEventPage.EventContainer>
      <StyledEventPage.EventHeader>
        <StyledEventPage.EventTitle>{event.title}</StyledEventPage.EventTitle>
        <StyledEventPage.CalendarButtonContainer onClick={handleBackToCalendar}>
          <LeftArrow />
          <p>Back to calendar</p>
        </StyledEventPage.CalendarButtonContainer>
      </StyledEventPage.EventHeader>
      <StyledEventPage.EventContent>
        <StyledEventPage.EventContentHeaderContainer>
          {event.image_url && <StyledEventPage.EventLogo src={event.image_url} />}
          <StyledEventPage.EventContentHeaderGroup>
            <StyledEventPage.EventContentHeader bold>
              {event.organization}
            </StyledEventPage.EventContentHeader>
            {event.category && (
              <StyledEventPage.EventContentHeader bold={false}>
                {event.category}
              </StyledEventPage.EventContentHeader>
            )}
          </StyledEventPage.EventContentHeaderGroup>
        </StyledEventPage.EventContentHeaderContainer>
        <StyledEventPage.EventInfoContainer>
          <StyledEventPage.EventInfoHeader>
            <StyledEventPage.EventContentDate>
              {eventDate.toFormat('MMMM d | EEEE')}
            </StyledEventPage.EventContentDate>
            {event.description && (
              <StyledEventPage.EventDescription>
                {event.description}
              </StyledEventPage.EventDescription>
            )}
          </StyledEventPage.EventInfoHeader>
          <div>
            <StyledEventPage.EventInfo>
              {eventRowIcons.map((eventRow) => {
                if (!eventRow.text) {
                  return null;
                }

                return (
                  <StyledEventPage.RowWithIcon key={eventRow.key}>
                    <StyledEventPage.IconContainer>{eventRow.icon}</StyledEventPage.IconContainer>
                    <p>{eventRow.text}</p>
                  </StyledEventPage.RowWithIcon>
                );
              })}
            </StyledEventPage.EventInfo>
            {/* <EventActionableContainer>
              <EventActionableButton
                text="Save To My Whenzy"
                onClick={() => console.log('Saved')}
              />
              <EventActionableButton text="Share" onClick={() => console.log('Share')} />
              <EventActionableButton
                text="Add to other calendar"
                onClick={() => console.log('Add to other calendar')}
              />
            </EventActionableContainer> */}
            {!!event.button_text && !!event.button_url && (
              <StyledEventPage.TicketButton
                href={event.button_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {event.button_text}
              </StyledEventPage.TicketButton>
            )}
          </div>
        </StyledEventPage.EventInfoContainer>
        {/* TODO: Buttons temporarily disabled until accounts implemented */}
      </StyledEventPage.EventContent>
      {event.embed_urls && event.embed_urls.length > 0 && (
        <StyledEventPage.EventSocialMediaContainer>
          {event.embed_urls.map((link) => (
            <SocialMediaEmbed key={link} link={link} />
          ))}
        </StyledEventPage.EventSocialMediaContainer>
      )}
    </StyledEventPage.EventContainer>
  );
};

export default Event;
